<template>
  <div class="pageContent">
    <noNavHeader title="买家中心" />
    <div class="matching">
      <el-row>
        <el-col :span="3">
          <el-col :span="24">
            <div class="matchingLeft">
              <div>
                <p class="matchingLeftTitle">挂摘牌</p>
              </div>
              <router-link to="/matching/qiugou">
                <p class="matchingLeftItem">我的求购</p>
              </router-link>
              <router-link to="/matching/order">
                <p class="matchingLeftItem">我的订单</p>
              </router-link>
              <router-link to="/matching/dealOrder">
                <p class="matchingLeftItem">我的成交</p>
              </router-link>
              <router-link to="/matching/holdProduct">
                <p class="matchingLeftItem">我的持仓</p>
              </router-link>
              <div>
                <p class="matchingLeftTitle">产品预售</p>
              </div>
              <router-link to="/matching/buyAgreeList">
                <p class="matchingLeftItem">产能采购协议</p>
              </router-link>
              <router-link to="/matching/pickGoods">
                <p class="matchingLeftItem">我要提货</p>
              </router-link>
              <router-link to="/matching/pickGoodsList">
                <p class="matchingLeftItem">提货查询</p>
              </router-link>
            </div>
          </el-col>
        </el-col>
        <el-col :span="21">
          <router-view />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import noNavHeader from "../base/header/noNavHeader";
export default {
  data() {
    return {
      tab: 1
    };
  },
  components: {
    noNavHeader
  },
  methods: {
    change(item) {
      this.tab = item;
    }
  }
};
</script>
<style lang="scss" scoped>
.matching {
  padding: 15px 0;
  width: 80%;
  min-width: 1300px;
  min-height: 1000px;
  margin: 0 auto;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.matching .matchingLeft {
  padding-left: 15px;
  line-height: 40px;
}
.matching .matchingLeft .matchingLeftTitle {
  font-size: 16px;
}
.matching .matchingLeft .matchingLeftItem {
  font-size: 14px;
  color: #9999a2;
}
.router-link-active p.matchingLeftItem {
  color: $commonThemeColor !important;
}
</style>
